import React, { useState } from 'react';
import Logo from '../img/logo.png';
import { Link, useLocation } from 'react-router-dom';
import styles from '../styles/navbar.module.scss';
import btnstyles from '../styles/button.module.scss';

const Navbar = ({ isDesktopExpertVideosSubNavActive,
	isDesktopResourceLibrarySubNavActive,
	setIsDesktopExpertSubNavActive,
	setIsDesktopResourceLibrarySubNavActive,
	setVideoToPlay }) => {
  const [click, setClick] = useState(false);
  const [isMobileExpertVideosSubNavActive, setIsMobileExpertVideosSubNavActive] = useState(false);
  const [isMobileResourceLibrarySubNavActive, setIsMobileResourceLibrarySubNavActive] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const location = useLocation();

  const closeHamburger = () => {
    setIsMobileExpertVideosSubNavActive(false);
    setIsMobileResourceLibrarySubNavActive(false);
    setClick(false);
  };

  const renderExpertVideosSubNav = () => {
    if (isMobileExpertVideosSubNavActive) {
      return (
        <>
          <Link to="/expert-videos/0" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              A Definitive Diagnosis of Narcolepsy&nbsp;<i className={'fas fa-chevron-right'} />
            </div>
	  </Link>
          <Link to="/expert-videos/1" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              Comorbidities and Hypocretin Deficiency in Narcolepsy{' '}<i className={'fas fa-chevron-right'} />
            </div>
	  </Link>
          <Link to="/expert-videos/2" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              Disease Management Considerations and Lifestyle Modifications{' '}<i className={'fas fa-chevron-right'} />
            </div>
	  </Link>
          <Link to="/expert-videos/3" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              Sodium Intake in Patients With Narcolepsy&nbsp;<i className={'fas fa-chevron-right'} />
            </div>
          </Link>
        </>
      );
    }
  };

  const renderResourceLibrarySubNav = () => {
    if (isMobileResourceLibrarySubNavActive) {
      return (
        <>
          <Link to="/resource-library/0" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              NarcolepsyLink.com <i className={'fas fa-chevron-right'} />
            </div>
	  </Link>
          <Link to="/resource-library/1" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-subrow']}`}>
              Brochure <i className={'fas fa-chevron-right'} />
            </div>
          </Link>
        </>
      );
    }
  };

  const renderMobileMenu = () => {
    if (click) {
      return (
        <div className={`${styles['mobile-hamburger']}`}>
          <div
            className={`${styles['mobile-nav-trigger']} ${styles['mobile-hamburger-close']}`}
            onClick={closeHamburger}
          >
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} style={{ color: 'white', fontSize: 30, margin: '0 5px 0 0' }} />
          </div>
          <Link to="/home" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-row']}`}>
              HOME <i className={'fas fa-chevron-right'} />
            </div>
          </Link>
          <div
            className={`${styles['mobile-hamburger-row']}`}
            onClick={() => setIsMobileExpertVideosSubNavActive(!isMobileExpertVideosSubNavActive)}
          >
            EXPERT VIDEOS{' '}
            <i
              className={
                isMobileExpertVideosSubNavActive ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
              }
            />
            {renderExpertVideosSubNav()}
          </div>
          <Link to="/trivia-game0" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-row']}`}>
              TRIVIA GAME <i className={'fas fa-chevron-right'} />
            </div>
          </Link>
          <div
            className={`${styles['mobile-hamburger-row']}`}
            onClick={() => setIsMobileResourceLibrarySubNavActive(!isMobileResourceLibrarySubNavActive)}
          >
            RESOURCE LIBRARY{' '}
            <i
              className={
                isMobileResourceLibrarySubNavActive? 'fas fa-chevron-down' : 'fas fa-chevron-right'
              }
            />
            {renderResourceLibrarySubNav()}
          </div>
          <Link to="/contact-us" onClick={closeHamburger}>
            <div className={`${styles['mobile-hamburger-row']}`}>
              CONTACT US <i className={'fas fa-chevron-right'} />
            </div>
          </Link>
        </div>
      );
    }
  };

  return (
    <div>
      <nav className={styles.navbar}>
        <Link to="/home" style={{ display: 'flex' }} onClick={() => {
		closeHamburger();
		setVideoToPlay('home-loop');
	}}>
          <img className={styles.logo} src={Logo} alt="logo" />
        </Link>
        <menu className={styles.menu}>
          <Link to="/home" className={`${styles['nav-link']} ${location.pathname.includes('/home') ? styles['nav-active'] : ''}`} onClick={() => {
		  closeMobileMenu();
		  setVideoToPlay('home-loop');
	  }}>
            Home
          </Link>
          <div className={`${styles['nav-link']} ${location.pathname.includes('/expert-videos') ? styles['nav-active'] : ''}`} style={{ display: "inline-block", cursor: "pointer" }}>
            <div onClick={() => setIsDesktopExpertSubNavActive(!isDesktopExpertVideosSubNavActive)}>Expert Videos <i className="fas fa-caret-down" /></div>
          </div>
          <Link to="/trivia-game0" className={`${styles['nav-link']} ${location.pathname.includes('/trivia-game') ? styles['nav-active'] : ''}`} onClick={() => {
		  closeMobileMenu();
		  setVideoToPlay('home-trivia-game');
	  }}>
            Trivia Game
          </Link>
          <div className={`${styles['nav-link']} ${location.pathname.includes('/resource-library') ? styles['nav-active'] : ''}`} style={{ display: "inline-block", cursor: "pointer" }}>
            <div onClick={() => setIsDesktopResourceLibrarySubNavActive(!isDesktopResourceLibrarySubNavActive)}>Resource Library <i className="fas fa-caret-down" /></div>
          </div>
          <Link to="/contact-us">
            <button className={`${btnstyles['btn']} ${location.pathname.includes('/contact-us') ? btnstyles['btn-active'] : ''}`} style={{ fontWeight: 700 }}>Contact Us</button>
          </Link>
        </menu>
        <div className={`${styles['mobile-nav-trigger']}`} onClick={handleClick}>
          <i className={'fas fa-bars'} />
        </div>
      </nav>
      {renderMobileMenu()}
    </div>
  );
};

export default Navbar;
